import { navigate } from "@reach/router";
import React, { useEffect } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

export default function NotFound() {
  useEffect(() => {
    navigate("/");
  }, []);
  return <Layout>{/* <pre>TODO: Add NotFound component</pre> */}</Layout>;
}

export const Head = () => {
  return (
    <SEO title="Not Found 🛑">
      <script type="application/ld+json">{JSON.stringify({})}</script>
    </SEO>
  );
};
